import { getDatabase, ref, onValue, push } from 'firebase/database';

const db = getDatabase();

document.addEventListener('DOMContentLoaded', function() {
    const saliSelect = document.getElementById('sali-select');
    const varausForm = document.getElementById('varaus-form');
    const varausContainer = document.getElementById('varaus-container');

    // Lataa kaikki salit valikkoon
    loadSalit();

    if (saliSelect) {
        saliSelect.addEventListener('change', function(event) {
            const selectedSaliId = event.target.value;
            if (selectedSaliId) {
                fetchSaliData(selectedSaliId);
            } else {
                varausContainer.innerHTML = 'Valitse sali nähdäksesi varaukset';
            }
        });
    }

    if (varausForm) {
        varausForm.addEventListener('submit', function(event) {
            event.preventDefault();
            
            const nimi = document.getElementById('nimi').value;
            const aika = document.getElementById('aika').value;
            const kesto = document.getElementById('kesto').value;

            const saliId = saliSelect.value;

            if (!saliId) {
                alert('Valitse ensin sali varauksen tekemiseksi');
                return;
            }

            const varausData = {
                nimi: nimi,
                aika: aika,
                kesto: kesto
            };

            const saliVarauksetRef = ref(db, `salivaraukset/salit/${saliId}/varaukset`);
            const newVarausRef = push(saliVarauksetRef);

            newVarausRef.set(varausData)
                .then(() => {
                    alert('Varaus tehty onnistuneesti!');
                    // Voit ohjata käyttäjän haluamallesi sivulle tässä
                })
                .catch((error) => {
                    console.error('Virhe varauksen tekemisessä:', error);
                });
        });
    }

    function loadSalit() {
        const salitRef = ref(db, 'salivaraukset/salit');
        onValue(salitRef, (snapshot) => {
            const salitData = snapshot.val();
            if (salitData) {
                populateSaliSelect(salitData);
            } else {
                saliSelect.innerHTML = '<option value="">Ei saatavilla olevia saleja</option>';
            }
        }, (error) => {
            console.error('Virhe salien lataamisessa:', error);
        });
    }

    function populateSaliSelect(salitData) {
        saliSelect.innerHTML = '<option value="">Valitse sali</option>';
        Object.keys(salitData).forEach((saliId) => {
            const sali = salitData[saliId];
            const option = document.createElement('option');
            option.value = saliId;
            option.textContent = sali.nimi;
            saliSelect.appendChild(option);
        });
    }

    function fetchSaliData(saliId) {
        const saliRef = ref(db, `salivaraukset/salit/${saliId}/varaukset`);
        onValue(saliRef, (snapshot) => {
            const saliData = snapshot.val();
            if (saliData) {
                displaySaliData(saliData);
            } else {
                varausContainer.innerHTML = 'Ei varauksia';
            }
        }, (error) => {
            console.error('Virhe varauksia haettaessa:', error);
        });
    }

    function displaySaliData(saliData) {
        // Tyhjennä vanhat varaukset
        varausContainer.innerHTML = '';
        
        // Oletetaan, että varaukset on tallennettu päivämäärän tai muun avaimen alla
        Object.keys(saliData).forEach((varausId) => {
            const varaus = saliData[varausId];
            const varausElement = document.createElement('div');
            varausElement.textContent = `Varaus: ${varaus.aika} - ${varaus.kesto}`;
            varausContainer.appendChild(varausElement);
        });
    }
});

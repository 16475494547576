import { getDatabase, ref, onValue, remove } from 'firebase-database';
import CryptoJS from 'crypto-js';

const db = getDatabase();
const currentURL = window.location.href; // Hae nykyinen URL

function deleteMessage(messageId) {
  const messageRef = ref(db, `messages/${messageId}`);
  remove(messageRef).then(() => {
    console.log("Viesti poistettu onnistuneesti");
    displayMessages(); // Päivitä viestit poiston jälkeen
  }).catch((error) => {
    console.error("Virhe viestin poistamisessa: ", error);
  });
}

function displayMessages() {
  const messagesRef = ref(db, 'messages');
  const messagesContainer = document.getElementById('messagesContainer');

  if (messagesContainer) {
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      let output = "";

      // Järjestä viestit uusimman mukaan
      const sortedMessageIds = Object.keys(data || {}).sort((a, b) => b - a);

      for (const messageId of sortedMessageIds) {
        const messageData = data[messageId];
        const encrypted = messageData.encrypted;
        const key = CryptoJS.AES.decrypt(messageData.key, 'your-secret-key').toString(CryptoJS.enc.Utf8);
        const decrypted = CryptoJS.AES.decrypt(encrypted, key).toString(CryptoJS.enc.Utf8);

        output += `<div class="message-container" id="message-${messageId}">
                     <h4>Viesti - Meddelande: ${decrypted}</h4>
                     <p>Lähettäjä - Avsändare: ${messageData.sender || 'Tuntematon'}</p>
                     <p>Lähetetty - Skickat: ${new Date(messageData.timestamp).toLocaleString()}</p>`;
        if (currentURL.includes("ilmoitustaulu.html")) {
          output += `<button onclick="deleteMessage('${messageId}')">Poista</button>`;
        }
        output += `</div>`;
      }

      messagesContainer.innerHTML = output || '<div class="message-container">Ei ilmoituksia - Inga meddelanden</div>';
    });
  } else {
    console.error("messagesContainer elementtiä ei löydy");
  }
}

window.displayMessages = displayMessages;
window.deleteMessage = deleteMessage;

displayMessages();

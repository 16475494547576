import { getDatabase, ref, onValue } from 'firebase/database';

const db = getDatabase();

document.addEventListener('DOMContentLoaded', function() {
    if (window.location.pathname === '/indexsali.html') {
        const saliSelect = document.getElementById('sali-select');
        const kalenteriContainer = document.getElementById('kalenteri-container');

        // Tarkistetaan käyttäjän autentikointi
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // Lataa kaikki salit valikkoon
                loadSalit();
            } else {
                window.location.href = "kirjautuminen.html";
            }
        });

        if (saliSelect) {
            saliSelect.addEventListener('change', function(event) {
                const selectedSaliId = event.target.value;
                if (selectedSaliId) {
                    fetchSaliData(selectedSaliId);
                } else {
                    kalenteriContainer.innerHTML = 'Valitse sali nähdäksesi varaukset';
                }
            });
        }

        function loadSalit() {
            const salitRef = ref(db, 'salivaraukset/salit');
            onValue(salitRef, (snapshot) => {
                const salitData = snapshot.val();
                if (salitData) {
                    populateSaliSelect(salitData);
                } else {
                    saliSelect.innerHTML = '<option value="">Ei saatavilla olevia saleja</option>';
                }
            }, (error) => {
                console.error('Virhe salien lataamisessa:', error);
            });
        }

        function populateSaliSelect(salitData) {
            saliSelect.innerHTML = '<option value="">Valitse sali</option>';
            Object.keys(salitData).forEach((saliId) => {
                const sali = salitData[saliId];
                const option = document.createElement('option');
                option.value = saliId;
                option.textContent = sali.nimi;
                saliSelect.appendChild(option);
            });
        }

        function fetchSaliData(saliId) {
            const saliRef = ref(db, `salivaraukset/salit/${saliId}/varaukset`);
            onValue(saliRef, (snapshot) => {
                const saliData = snapshot.val();
                if (saliData) {
                    displaySaliData(saliData);
                } else {
                    kalenteriContainer.innerHTML = 'Ei varauksia';
                }
            }, (error) => {
                console.error('Virhe varauksia haettaessa:', error);
            });
        }

        function displaySaliData(saliData) {
            kalenteriContainer.innerHTML = '';
            Object.keys(saliData).forEach((varausId) => {
                const varaus = saliData[varausId];
                const varausElement = document.createElement('div');
                varausElement.textContent = `Varaus: ${varaus.aika} - ${varaus.kesto}`;
                kalenteriContainer.appendChild(varausElement);
            });
        }
    }
});

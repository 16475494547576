// Purpose: Palautenäytön toiminnallisuus
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, get, remove } from 'firebase/database';  // Lisää tämä rivi
// Alustetaan Firebase (kuten aiemmin)
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

function downloadFeedback(category) {
    const palautteetRef = ref(db, `palautteet/${category}`);
    get(palautteetRef).then((snapshot) => {
        if (snapshot.exists()) {
            const data = snapshot.val();
            const dataStr = JSON.stringify(data, null, 2);
            const blob = new Blob([dataStr], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'palautteet.json';
            a.click();
            URL.revokeObjectURL(url);
        } else {
            alert('Ei palautteita ladattavaksi');
        }
    });
}

window.deleteFeedback = function(category, date, key) {
    const palauteRef = ref(db, `palautteet/${category}/${date}/${key}`);
    remove(palauteRef).then(() => {
        alert('Palaute poistettu');
        // Päivitä palautteet
        const palautteetContainer = document.getElementById('palautteet-container');
        palautteetContainer.innerHTML = '';  // Tyhjennä container
        loadFeedback();  // Lataa palautteet uudelleen
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
}

function loadFeedback(category) {
    const palautteetContainer = document.getElementById('palautteet-container');
    palautteetContainer.innerHTML = ''; // Tyhjennä aiemmat palautteet

    // Muutetaan polkua kategoriasta riippuen
    const palautteetRef = ref(db, `palautteet/${category}`);
    get(palautteetRef).then((snapshot) => {
        if (snapshot.exists()) {
            snapshot.forEach((dateSnapshot) => {
                const date = dateSnapshot.key;
                dateSnapshot.forEach((palauteSnapshot) => {
                    const palauteKey = palauteSnapshot.key;
                    const palauteData = palauteSnapshot.val();
                    const palauteElement = document.createElement('div');
                    // Sisällön muodostus ja esittäminen
                    palauteElement.innerHTML = `
                        <strong>Päivämäärä:</strong> ${date}<br>
                        <strong>Palaute:</strong> ${palauteData.palaute}<br>
                        <button onclick="deleteFeedback('${category}', '${date}', '${palauteKey}')">Poista</button>
                        <hr>
                    `;
                    palautteetContainer.appendChild(palauteElement);
                });
            });
        } else {
            palautteetContainer.textContent = `Ei palautteita kategoriassa: ${category}`;
        }
    });
    // Lataa-painikkeen lisääminen
    const downloadButton = document.createElement('button');
    downloadButton.textContent = 'Lataa palautteet';
    downloadButton.onclick = () => downloadFeedback(category);
    palautteetContainer.insertBefore(downloadButton, palautteetContainer.firstChild);
}

if (window.location.pathname === '/ilmoitustaulu.html' || window.location.pathname === '/admin-panel.html') {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // Käyttäjä on kirjautunut sisään, hae palautteet tietokannasta
            loadFeedback();
        } else {
            // Käyttäjä ei ole kirjautunut sisään, ohjaa kirjautumissivulle
            window.location.href = "kirjautuminen.html";
        }
    });
}

window.loadFeedback = loadFeedback;
window.downloadFeedback = downloadFeedback;
//salasana.js
// Desc: Salasanan salaus- ja purkamisfunktiot
import CryptoJS from 'crypto-js';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, set, onValue } from 'firebase/database';

// Alustetaan Firebase
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

document.addEventListener("DOMContentLoaded", function() {
    // Tässä vaiheessa koko DOM on ladattu
    document.getElementById('encryptButton').addEventListener('click', encryptMessage);
    document.getElementById('decryptButton').addEventListener('click', decryptMessage);
});

function encryptMessage() {
    var message = document.getElementById('message').value;
    var key = document.getElementById('key').value;
    var encrypted = CryptoJS.AES.encrypt(message, key).toString();
    
    var messageId = push(ref(db, 'salaisuudet')).key;  
    var messageData = {
        encrypted: encrypted,
        key: CryptoJS.AES.encrypt(key, 'Cerveku').toString(),
    };

    set(ref(db, 'salaisuudet/' + messageId), messageData);
    document.getElementById('encrypted').innerText = encrypted;
}

function decryptMessage() {
    var userKey = document.getElementById('key').value;
    onValue(ref(db, 'salaisuudet'), snapshot => {
        const data = snapshot.val();
        let decryptedMessages = '';
        for (let id in data) {
            const encrypted = data[id].encrypted;
            const encryptedKey = data[id].key;
            const key = CryptoJS.AES.decrypt(encryptedKey, 'Cerveku').toString(CryptoJS.enc.Utf8);
            if (key === userKey) {  // Tarkista, onko annettu avain sama kuin viestin salattu avain
                try {
                    const decrypted = CryptoJS.AES.decrypt(encrypted, key).toString(CryptoJS.enc.Utf8);
                    if (!decrypted) {
                        throw new Error('Decryption failed or resulted in empty string.');
                    }
                    decryptedMessages += decrypted + '\n';
                } catch (error) {
                    console.error('Error decrypting message:', error.message);
                    alert('Salasanan purkaminen epäonnistui. Väärä salausavain.');
                    return;  // Keskeyttää for-loopin ja poistuu funktiosta, kun virhe tapahtuu
                }
            }
        }
        document.getElementById('decrypted').innerText = decryptedMessages;
    });
}

window.encryptMessage = encryptMessage;
window.decryptMessage = decryptMessage;

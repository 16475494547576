import './login.js';
import './palaute.js';
import './palautenaytto.js';
import './salasana.js';
import './index.js';
import './user.js';
import './sali.js';
import './indexsali.js';
import './config.js';
import './indexvaraus.js'

import CryptoJS from 'crypto-js';
import { initializeApp } from 'firebase-app';
import { getDatabase, ref, onValue } from 'firebase-database';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';

// Alustetaan Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// Alustetaan Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);



let today = new Date();
let currentDayIndex = today.getDay(); // 0 = sunnuntai, 1 = maanantai, jne.
let currentWeek = getWeekNumber(today);

function fetchData() {
  const currentDay = ["su", "ma", "ti", "ke", "to", "pe", "la"][currentDayIndex];
  const refPath = `weeks/${currentWeek}/${currentDay}`;
  const dayMenuRef = ref(db, refPath);

  onValue(dayMenuRef, (snapshot) => {
    const data = snapshot.val();
    const dataContainer = document.getElementById('dataContainer');

    if (data) {
      dataContainer.innerHTML = `<h3>Viikko ${currentWeek}, ${currentDay}</h3><p>Ruoka: ${data}</p>`;
    } else {
      dataContainer.innerHTML = 'Ei tietoa saatavilla';
    }
  });
}

// Funktio viikkonumeron saamiseksi
function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return weekNo;
}

document.getElementById('nextDay').addEventListener('click', () => {
    currentDayIndex = (currentDayIndex + 1) % 7; // Siirry seuraavaan päivään ja palaa sunnuntaihin, jos nykyinen on lauantai
    if (currentDayIndex === 0) { // Jos uusi päivä on sunnuntai, siirry seuraavaan viikkoon
        currentWeek++;
    }
    updateDatabaseReference(); // Oletetaan, että tällä funktiolla päivitetään tietokantaviite ja haetaan uudet tiedot
});

document.getElementById('prevDay').addEventListener('click', () => {
    currentDayIndex = (currentDayIndex - 1 + 7) % 7; // Siirry edelliseen päivään ja palaa lauantaihin, jos nykyinen on sunnuntai
    if (currentDayIndex === 6) { // Jos uusi päivä on lauantai ja nykyinen oli sunnuntai, siirry edelliseen viikkoon
        currentWeek--;
    }
    updateDatabaseReference(); // Oletetaan, että tällä funktiolla päivitetään tietokantaviite ja haetaan uudet tiedot
});
  
// Funktio, joka päivittää tietokantaviitteen ja hakee uudet tiedot
function updateDatabaseReference() {
    const currentDay = ["su", "ma", "ti", "ke", "to", "pe", "la"][currentDayIndex];
    const refPath = `weeks/${currentWeek}/${currentDay}`;
    const dayMenuRef = ref(db, refPath);  // Päivitetään tietokantaviite uudella polulla

    // Kutsu fetchData-funktiota päivitettyä tietokantaviitettä käyttäen
    fetchData();
}

document.addEventListener('DOMContentLoaded', function() {
  var cookieBanner = document.getElementById("cookie-banner");

  // Tarkista, onko evästeet jo hyväksytty
  if (!localStorage.getItem('cookiesAccepted')) {
    cookieBanner.style.display = "block";
  }

  document.getElementById("accept-cookies").addEventListener("click", function() {
    localStorage.setItem('cookiesAccepted', 'true');
    cookieBanner.style.display = "none";
  });
});

fetchData();
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, set } from 'firebase/database';

// Firebase-configuraation alustus
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

// Funktio palautteen lähettämiseen Firebaseen
function submitFeedback(event, feedbackType) {
    event.preventDefault();

    const date = document.getElementById('palaute-date').value;
    const palaute = document.getElementById(`palaute-${feedbackType}`).value;

    // Muut kentät voit lisätä tähän tarpeen mukaan

    const newFeedbackRef = push(ref(db, `palautteet/${feedbackType}/${date}`));
    set(newFeedbackRef, { palaute }) // Lisää muut kentät tähän
    .then(() => {
        alert('Palaute lähetetty!');
        // Voit siirtää käyttäjän takaisin pääsivulle tai muulle sivulle
    })
    .catch(error => {
        console.error('Error writing to database:', error);
        alert('Palaute epäonnistui.');
    });
}

// Kuuntelijoiden asettaminen kullekin lomakkeelle
document.addEventListener("DOMContentLoaded", function() {
    const kouluruokaForm = document.getElementById('kouluruoka-form');
    const siivousForm = document.getElementById('siivous-form');
    const ictForm = document.getElementById('ict-form');
    const kouluform = document.getElementById('koulu-form');

    if (kouluruokaForm) {
        kouluruokaForm.addEventListener('submit', (e) => submitFeedback(e, 'kouluruoka'));
    }
    if (siivousForm) {
        siivousForm.addEventListener('submit', (e) => submitFeedback(e, 'siivous'));
    }
    if (ictForm) {
        ictForm.addEventListener('submit', (e) => submitFeedback(e, 'ict'));
    }
    if (kouluform) {
        kouluForm.addEventListener('submit', (e) => submitFeedback(e, 'koulu'));
    }

    // Lisää tarvittaessa muita lomakkeita ja niiden kuuntelijoita
});

import { getDatabase, ref, push, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const auth = getAuth();
const db = getDatabase();

document.addEventListener('DOMContentLoaded', function() {
    const saliForm = document.getElementById('sali-form');
    
    if (saliForm) {
        saliForm.addEventListener('submit', function(event) {
            event.preventDefault();
            
            const saliNimi = document.getElementById('sali-nimi').value;
            const saliKuvaus = document.getElementById('sali-kuvaus').value;

            const salitRef = ref(db, 'salivaraukset/salit');
            const newSaliRef = push(salitRef);

            const saliData = {
                nimi: saliNimi,
                kuvaus: saliKuvaus,
                varaukset: {}
            };

            update(newSaliRef, saliData).then(() => {
                alert('Sali lisätty onnistuneesti!');
                window.location.href = "indexsali.html";
            }).catch((error) => {
                console.error('Virhe salin lisäämisessä:', error);
            });
        });
    }
});
// Desc: Kirjautumislogiikka
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';

// Alustetaan Firebase
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

// Kirjautumislogiikka
document.addEventListener("DOMContentLoaded", function() {
    const loginForm = document.getElementById('login-form');
    const loginFormContainer = document.getElementById('login-form-container');
    const authBtn = document.getElementById('authBtn');
    
    if (loginForm) {
        loginForm.addEventListener('submit', function(event) {
            event.preventDefault();
            
            const email = document.getElementById('email').value;
            const password = document.getElementById('password').value;
          
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                window.location.href = "admin-panel.html";
            })
            .catch((error) => {
                window.location.href = "login-failed.html";
            });
        });
    }
    
    if (authBtn) {
        authBtn.addEventListener('click', function() {
            if (loginFormContainer.style.display === "none" || loginFormContainer.style.display === "") {
                loginFormContainer.style.display = "block";
                authBtn.textContent = 'Peruuta';  // Vaihda nappulan teksti 'Peruuta' kun lomake on näkyvissä
            } else {
                loginFormContainer.style.display = "none";
                authBtn.textContent = 'Kirjaudu sisään';  // Vaihda nappulan teksti 'Kirjaudu sisään' kun lomake on piilotettu
            }
        });
    }
});

// Linkin päivitys kirjautumistilan mukaan
const authLink = document.getElementById('authLink');

if (authLink) {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            authLink.textContent = 'Kirjaudu ulos';
            authLink.href = "javascript:signOutUser()";
        } else {
            authLink.textContent = 'Kirjaudu sisään';
            authLink.href = "kirjautuminen.html";
        }
    });
}

// Uloskirjautumisfunktio
function signOutUser() {
    signOut(auth).then(() => {
        console.log('Käyttäjä kirjautui ulos');
        window.location.href = "index.html";
    }).catch((error) => {
        console.error('Uloskirjautuminen epäonnistui:', error);
    });
}

// Nappulan näkyvyyden päivittäminen
function updateButtonVisibility(user) {
    const feedbackBtn = document.getElementById('feedbackBtn');
    if (feedbackBtn) {
        if (user) {
            feedbackBtn.style.display = 'block';  // Näytä nappi, jos käyttäjä on kirjautunut sisään
        } else {
            feedbackBtn.style.display = 'none';  // Piilota nappi, jos käyttäjä ei ole kirjautunut sisään
        }
    }
}

onAuthStateChanged(auth, (user) => {
    updateButtonVisibility(user);  // Päivitä nappulan näkyvyys, kun kirjautumistila muuttuu
});

// Liitä signOutUser-funktio window-objektiin, jotta se on globaalisti saatavilla
window.signOutUser = signOutUser;

import { getDatabase, ref, set, remove, onValue } from 'firebase-database';
import CryptoJS from 'crypto-js';

const db = getDatabase();

function addMessage() {
    const message = document.getElementById('newMessage').value;
    const senderName = document.getElementById('senderName').value;
    const timestamp = new Date().toISOString();
    const key = 'some-random-key';
    const encrypted = CryptoJS.AES.encrypt(message, key).toString();
    const messageId = Date.now().toString();
    const messageData = {
        encrypted: encrypted,
        key: CryptoJS.AES.encrypt(key, 'your-secret-key').toString(),
        sender: senderName,
        timestamp: timestamp
    };
    if (!message.trim() || !senderName.trim()) {
      alert('Viesti ja lähettäjän nimi ovat pakollisia.');
      return;
  }

    set(ref(db, 'messages/' + messageId), messageData);
    document.getElementById('newMessage').value = ''; // Tyhjentää tekstilaatikon
    document.getElementById('senderName').value = ''; // Tyhjentää lähettäjän nimen
}


function deleteMessage(messageId) {
  remove(ref(db, 'messages/' + messageId));
}

function editMessage(messageId, message) {
  const newMessage = prompt('Anna uusi viesti:', message);
  const key = 'some-random-key';
  const encrypted = CryptoJS.AES.encrypt(newMessage, key).toString();
  const messageData = {
      encrypted: encrypted,
      key: CryptoJS.AES.encrypt(key, 'your-secret-key').toString()
  };

  set(ref(db, 'messages/' + messageId), messageData);
}

function displayMessages() {
  const messagesRef = ref(db, 'messages/');
  onValue(messagesRef, (snapshot) => {
      const messagesContainer = document.getElementById('messages');
      messagesContainer.innerHTML = ''; // Tyhjentää vanhat viestit
      const messages = snapshot.val();
      if (messages) {
          Object.keys(messages).forEach((messageId) => {
              const message = messages[messageId];
              // Tässä voisi purkaa viestin salausta tarvittaessa
              const messageElement = document.createElement('div');
              messageElement.innerText = `Lähettäjä: ${message.sender} - ${new Date(message.timestamp).toLocaleString()}: ${message.encrypted}`;
              messagesContainer.appendChild(messageElement);
          });
      } else {
          messagesContainer.innerText = 'Ei ilmoituksia - Inga meddelanden';
      }
  });
}

window.addMessage = addMessage;
window.deleteMessage = deleteMessage;
window.editMessage = editMessage;
window.onload = displayMessages; // Kutsuu displayMessages kun sivu ladataan